<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
              <b-button v-if="$can('create', 'Workplace')" :to="{ name: 'workplace-new' }" variant="primary">
                <span class="text-nowrap">Nova Unidade</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refWorkplaceListTable"
        style="min-height: 300px"
        class="position-relative"
        :items="fetchWorkplaces"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(name)="data">
          <b-media vertical-align="center">
            {{ data.item.name }}
          </b-media>
        </template>
        <template #cell(phone)="data">
          <b-media vertical-align="center">
            {{ phoneMask(data.item.phone) }}
          </b-media>
        </template>
        <template #cell(city)="data">
          <b-media vertical-align="center">
            {{ data.item.address[0].city.name }}-{{ data.item.address[0].city.state.abbreviation }}
          </b-media>
        </template>
        <template #cell(person_type)="data">
          <b-badge v-if="data.item.person_type" pill :variant="data.item.person_type === 'PF' ? 'light-success' : 'light-danger'">
            {{ data.item.person_type }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('edit', 'Workplace')"
              :to="{ name: 'workplace-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.service_type_can_released === 'physician_duty' || data.item.service_type_can_released === 'all'"
              :to="{ name: 'physician-duty-edit', params: { id: data.item.id } }"
              :disabled="!data.item.person_type"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50">
                Escalas <div v-if="!data.item.person_type" class="font-small-2 text-danger">(sem Tipo Plantão)</div>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.service_type_can_released === 'service_record' || data.item.service_type_can_released === 'all'"
              :to="{ name: 'physician-duty-services-edit', params: { id: data.item.id } }"
              :disabled="!data.item.person_type"
            >
              <feather-icon icon="ToolIcon" />
              <span class="align-middle ml-50">
                Serviços <div v-if="!data.item.person_type" class="font-small-2 text-danger">(sem Tipo Plantão)</div>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalWorkplaces"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BTable, BMedia, BPagination, BButton, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import workplaceStoreModule from '../workplaceStoreModule'
import useWorkplaceList from './useWorkplaceList'

export default {
  components: {
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-workplace'
    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, workplaceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    const workplaces = ref([])
    const workplace = ref({
      address: {
        postal_code: '',
        street: '',
        neighborhood: '',
        complement: '',
        city_id: '',
        state_id: '',
      },
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })

    const clearWorkplaceData = () => {
      workplace.value = {
        address: {
          postal_code: '',
          street: '',
          neighborhood: '',
          complement: '',
          city_id: '',
          state_id: '',
        },
        duties: {
          6: '',
          12: '',
          24: '',
        },
      }
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isWorkplaceHandlerSidebarActive = ref(false)

    const createWorkplace = () => {
      isWorkplaceHandlerSidebarActive.value = true
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,

      refetchData,
      fetchWorkplaces,

      updateWorkplace,
      searchQuery,
      sortBy,
      phoneMask,
    } = useWorkplaceList()

    return {
      workplaces,

      updateWorkplace,
      searchQuery,
      fetchWorkplaces,
      perfectScrollbarSettings,
      sortBy,
      isWorkplaceHandlerSidebarActive,

      // Click Handler
      createWorkplace,

      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,
      refetchData,
      workplace,
      clearWorkplaceData,
      phoneMask,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-workplace-handle {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-workplace-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
