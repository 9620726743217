<template>
  <component :is="'b-card'">
    <div class="physician-form">
      <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
        <b-form
          @submit.prevent="handleSubmit(save)"
        >
          <b-tabs
            pills
          >
            <b-tab active>
              <template #title>
                <div>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Geral</span>
                </div>
              </template>

              <b-row>
                <b-col cols="12" md="12" xl="8">
                  <validation-provider
                    #default="validationContext"
                    name="Nome"
                    rules="required"
                  >
                    <b-form-group
                      label="Nome"
                      label-for="nome"
                    >
                      <b-form-input
                        id="nome"
                        v-model="workplaceLocal.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Nome"
                      />
                      <small class="text-danger">{{ validationContext.errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" xl="4">
                  <validation-provider
                    name="Bloqueio em"
                    rules=""
                  >
                    <b-form-group
                      label="Bloqueio em"
                      label-for="bloqueio em"
                    >
                      <b-form-datepicker
                        id="blocked_at"
                        v-model="workplaceLocal.blocked_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="pt-BR"
                        label-help=""
                        label-reset-button="Limpar"
                        :reset-button="true"
                        label-no-date-selected="Selecione uma data"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" xl="8">
                  <div class="d-flex">
                    <feather-icon
                      icon="DollarSignIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50">
                      Pagamento
                      <small class="text-muted">
                        dia {{ workplaceLocal.payday || 'X' }} a cada {{ workplaceLocal.payment_month || 'X' }} meses
                      </small>
                    </h4>
                  </div>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Dia de pagamento"
                        rules="required"
                      >
                        <b-form-group
                          label="Dia de pagamento"
                          label-for="payday"
                        >
                          <v-select
                            v-model="workplaceLocal.payday"
                            autocomplete="payday"
                            :options="payDayOptions"
                            :clearable="false"
                            label="label"
                            :reduce="value => value.value"
                            input-id="payday"
                            placeholder="Dia de pagamento"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Mês competência"
                        rules="required"
                      >
                        <b-form-group
                          label="Mês competência"
                          label-for="payment_month"
                        >
                          <v-select
                            v-model="workplaceLocal.payment_month"
                            autocomplete="payment_month"
                            :options="paymentMonthOptions"
                            :clearable="false"
                            label="label"
                            :reduce="value => value.value"
                            input-id="payment_month"
                            placeholder="Mês competência"
                          />
                          <small class="text-danger">{{ validationContext.errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" xl="8">
                  <div class="d-flex">
                    <feather-icon
                      icon="MailIcon"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50">
                      Endereço
                    </h4>
                  </div>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Contato"
                        rules="required"
                      >
                        <b-form-group
                          label="Contato"
                          label-for="contato"
                        >
                          <b-form-input
                            id="contato"
                            v-model="workplaceLocal.contact"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Contato"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Telefone"
                        label-for="telefone"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Telefone"
                          vid="phone"
                          rules="required|min:10"
                        >
                          <cleave
                            id="phone"
                            v-model="workplaceLocal.phone"
                            class="form-control"
                            :raw="true"
                            :class="{'is-invalid': validationContext.errors.length}"
                            :options="options.phone"
                            placeholder="Telefone"
                          />
                          <small
                            v-if="validationContext.touched || validationContext.errors[0]"
                            class="text-danger"
                          >{{ validationContext.errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="CEP"
                        label-for="CEP"
                      >
                        <validation-provider
                          #default="{ errors, touched }"
                          name="CEP"
                          vid="CEP"
                          rules="required|min:8"
                        >
                          <cleave
                            id="CEP"
                            v-model="workplaceLocal.address.postal_code"
                            class="form-control"
                            :raw="true"
                            placeholder="CEP"
                            :options="options.postalCode"
                            :class="{'is-invalid': errors.length}"
                            @blur="searchCep(workplaceLocal.address)"
                          />
                          <small v-if="touched" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="state"
                        rules="required"
                      >
                        <b-form-group
                          label="Estado"
                          label-for="state"
                        >
                          <v-select
                            v-model="selectedState"
                            autocomplete="state"
                            :options="stateOptions"
                            :clearable="false"
                            label="text"
                            :reduce="value => value.value"
                            input-id="state"
                            placeholder="Estado"
                            :disabled="!stateOptions.length"
                            :state="getValidationState(validationContext)"
                            @input="refreshCities(selectedState)"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="city"
                        rules="required"
                      >
                        <b-form-group
                          label="Cidade"
                          label-for="cidade"
                        >
                          <v-select
                            v-model="selectedCity"
                            autocomplete="city"
                            :options="cityOptions"
                            :clearable="false"
                            label="text"
                            :reduce="value => value.value"
                            input-id="city"
                            placeholder="Cidade"
                            :disabled="!cityOptions.length"
                            :state="getValidationState(validationContext)"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Bairro"
                        rules="required"
                      >
                        <b-form-group
                          label="Bairro"
                          label-for="bairro"
                        >
                          <b-form-input
                            id="bairro"
                            v-model="workplaceLocal.address.neighborhood"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Bairro"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="rua"
                        rules="required"
                      >
                        <b-form-group
                          label="Rua"
                          label-for="rua"
                        >
                          <b-form-input
                            id="rua"
                            v-model="workplaceLocal.address.street"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Rua"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="número"
                        rules="required"
                      >
                        <b-form-group
                          label="Número"
                          label-for="número"
                        >
                          <b-form-input
                            id="número"
                            v-model="workplaceLocal.address.number"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Número"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <validation-provider
                    #default="validationContext"
                    name="complement"
                  >
                    <b-form-group
                      label="Complemento"
                      label-for="complemento"
                    >
                      <b-form-input
                        id="complemento"
                        v-model="workplaceLocal.address.complement"
                        autofocus
                        trim
                        placeholder="Complemento"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab>
              <template #title>
                <div>
                  <feather-icon
                    icon="DollarSignIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">
                    Tipos
                  </span>
                </div>
              </template>

              <b-row>
                <b-card
                  v-for="(duty, i) in workplaceLocal.duties"
                  :key="`duty-${duty.id}`"
                  overlay
                  no-body
                  class="my-1 ml-1 p-1 border"
                >
                  <div class="duty-header">
                    <b-button
                      variant="outlined"
                      class="mr-1 duty-remove"
                      :disabled="workplaceLocal.duties.length === 1"
                      @click="removeDuty(duty.id)"
                    >
                      <feather-icon
                        class="-m-1 cursor-pointer"
                        icon="XIcon"
                        size="16"
                      />
                    </b-button>
                  </div>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      :name="`duty_type_id-${duty.id}`"
                      rules="required"
                    >
                      <b-form-group
                        label="Especialidade"
                        :label-for="`duty_type_id-${duty.id}`"
                      >
                        <v-select
                          v-model="duty.duty_type_id"
                          autocomplete="type"
                          :options="dutyTypesOptions"
                          :clearable="false"
                          label="type"
                          item-value="id"
                          item-text="type"
                          :reduce="value => value.id"
                          placeholder="Tipo"
                          input-id="type"
                          @input="changeDutyType(duty)"
                        />
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      #default="{ errors }"
                      :name="`duty_type_complement-${duty.id}`"
                    >
                      <b-form-group
                        label="Tipo complemento"
                        :label-for="`duty_type_complement-${duty.id}`"
                      >
                        <b-form-input
                          :id="`duty_type_complement-${duty.id}`"
                          v-model="duty.complement"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :name="`duty_type_complement-${duty.id}`"
                          autocomplete="off"
                          placeholder=""
                          :disabled="!!duty.service_type"
                        />
                      </b-form-group>
                    </validation-provider>
                    <div
                      v-for="(remuneration, index) in Object.keys(duty.remuneration)"
                      :key="`remuneration-${duty.id}-${index}`"
                    >
                      <validation-provider
                        #default="{ errors, touched }"
                        :name="`Valor hora ${getRemunerationType(remuneration)}`"
                        :vid="`duty-${i}`"
                        rules="required"
                      >
                        <b-form-group>
                          <label :for="`duty-${i}`" class="d-block duty-label">
                            Valor hora <b>{{ getRemunerationType(remuneration) }}</b> <span>({{ getNumberAsText(duty.remuneration[remuneration]) || 'zero reais' }})</span>
                          </label>
                          <cleave
                            :id="`duty-${i}`"
                            v-model="duty.remuneration[remuneration]"
                            class="form-control"
                            :class="{'is-invalid': touched && errors.length}"
                            :options="options.number"
                            :raw="true"
                            :disabled="!!duty.service_type"
                          />
                          <small v-if="touched" class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-card>
                <div class="d-flex flex-column justify-content-center">
                  <b-button
                    variant="success"
                    class="m-1"
                    type="submit"
                    :disabled="workplaceLocal.duties.length === dutyTypesOptions.length"
                    @click="addDuty"
                  >
                    <feather-icon
                      class="-m-1 cursor-pointer"
                      icon="PlusIcon"
                      size="24"
                    />
                  </b-button>
                </div>
              </b-row>
            </b-tab>
          </b-tabs>
          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mt-1 mr-1"
                type="submit"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </component>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import {
  BCard, BTab, BTabs, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import slugify from 'slugify'
import Ripple from 'vue-ripple-directive'
import { uniq } from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref, toRefs } from '@vue/composition-api'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// import { Money } from 'v-money'
import store from '@/store'
import workplaceStoreModule from '@/views/workplace/workplaceStoreModule'
import useWorkplaceHandler from '@/views/workplace/workplace-form/useWorkplaceHandler'
import cep from 'cep-promise'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { numberAsText } from '@core/utils/utils'

export default {
  components: {
    // Money,
    BFormDatepicker,
    BCard,
    BTab,
    BTabs,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    Cleave,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      url,
      options: {
        number: {
          numeralPositiveOnly: true,
          numericOnly: true,
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
          numeralDecimalScale: 2,
          prefix: 'R$ ',
          rawValueTrimPrefix: true,
        },
        phone: {
          delimiters: ['(', ') ', '-'],
          blocks: [0, 2, 5, 4],
          numericOnly: true,
        },
        postalCode: {
          delimiters: ['.', '-'],
          blocks: [2, 3, 3],
          numericOnly: true,
        },
      },
    }
  },
  methods: {
    getNumberAsText: numberAsText,
  },
  setup(props, { emit }) {
    const toast = useToast()

    const WORKPLACE_STORE = 'app-workplace'
    if (!store.hasModule(WORKPLACE_STORE)) { store.registerModule(WORKPLACE_STORE, workplaceStoreModule) }
    onUnmounted(() => {
      if (store.hasModule(WORKPLACE_STORE)) { store.unregisterModule(WORKPLACE_STORE) }
    })

    const selectedState = ref('')
    const selectedCity = ref('')
    const dutyTypesOptions = ref([])
    const blankWorkplace = {
      name: '',
      contact: '',
      phone: '',
      payday: '',
      payment_month: '',
      blocked_at: '',
      address: {
        city_id: '',
      },
      duties: [
        {
          id: uuidv4(),
          duty_type_id: '',
          complement: '',
          service_type: null,
          remuneration: {
            week: 0,
            weekend: 0,
          },
        },
      ],
    }
    const workplaceLocal = ref(JSON.parse(JSON.stringify(blankWorkplace)))

    const resetWorkplaceData = () => {
      workplaceLocal.value = JSON.parse(JSON.stringify(blankWorkplace))
    }

    const {
      refFormObserver,
      getValidationState,
      clearForm,
    } = formValidation(resetWorkplaceData)

    store.dispatch(`${WORKPLACE_STORE}/dutyTypes`)
      .then(({ data }) => {
        dutyTypesOptions.value = data.data
      })

    const {
      fetchStates,
      fetchCities,
      stateOptions,
      cityOptions,
      payDayOptions,
      paymentMonthOptions,
    } = useWorkplaceHandler(toRefs(props), emit)

    const getDutyId = duty => duty.duty_type_id + (slugify(duty.complement || '', { lower: true }))

    if (router.currentRoute.params.id) {
      fetchStates()
        .then(() => store.dispatch('app-workplace/fetchWorkplace', { id: router.currentRoute.params.id }))
        .then(async response => {
          const duties = {}
          response.data.data.duties.forEach(duty => {
            if (!duties[getDutyId(duty)]) {
              duties[getDutyId(duty)] = {
                id: uuidv4(),
                duty_type_id: duty.duty_type_id,
                service_type: duty.service_type,
                complement: duty.complement,
                remuneration: {
                  week: 0,
                  weekend: 0,
                },
              }
            }
            const remuneration = duty.remuneration / 100
            const formatted = String(remuneration).replace('.', ',')
            duties[getDutyId(duty)].remuneration[duty.value_modifier_type] = formatted
          })
          const workplace = response.data.data
          const stateId = workplace.address[0].city.state.id
          if (stateId) await fetchCities(stateId)
          selectedCity.value = workplace.address[0].city.id
          selectedState.value = stateId
          workplaceLocal.value = {
            ...workplace,
            duties: Object.values(duties),
            address: {
              complement: workplace.address[0].complement,
              neighborhood: workplace.address[0].neighborhood,
              number: workplace.address[0].number,
              postal_code: workplace.address[0].postal_code,
              street: workplace.address[0].street,
              city_id: workplace.address[0].city.id,
            },
          }
        })
    } else {
      clearForm()
      fetchStates()
    }

    function changeDutyType(duty) {
      const d = dutyTypesOptions.value.find(({ id }) => id === duty.duty_type_id)
      // eslint-disable-next-line no-param-reassign
      duty.service_type = d.service_type
    }

    function addDuty() {
      workplaceLocal.value.duties.push({
        id: uuidv4(),
        duty_type_id: '',
        complement: '',
        service_type: null,
        remuneration: {
          week: 0,
          weekend: 0,
        },
      })
    }

    async function refreshCities(stateId) {
      const cities = await fetchCities(stateId)
      selectedCity.value = cities[0].value
    }

    function searchCep(address) {
      if (!address.postal_code || address.postal_code.length < 8) return
      cep(address.postal_code)
        .then(async data => {
          /* eslint-disable no-param-reassign */
          const state = stateOptions.value.find(s => s.text === data.state)
          if (state) selectedState.value = state.value
          await fetchCities(state.value)
          const city = cityOptions.value.find(s => s.text === data.city)
          if (city) {
            address.city_id = city.value
            selectedCity.value = city.value
          }
          address.street = data.street
          address.neighborhood = data.neighborhood
        })
    }

    function save() {
      const workplace = workplaceLocal.value
      workplace.address.city_id = selectedCity.value
      const dutyTypes = workplace.duties.map(d => getDutyId(d))
      if (uniq(dutyTypes).length !== dutyTypes.length) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Tipos de escala duplicados',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      const getRemunerations = d => {
        const remunerations = {}
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const duration in d.remuneration) {
          remunerations[duration] = parseInt(d.remuneration[`${duration}`] * 100, 10)
        }
        return remunerations
      }

      const duties = []
      workplace.duties.forEach(d => {
        if (d.service_type) {
          duties.push({
            ...d, value_modifier_type: 'week', duration: 1, remuneration: 0,
          })
        } else {
          const remunerations = getRemunerations(d)
          Object.keys(remunerations).forEach(type => {
            duties.push({
              ...d, value_modifier_type: type, duration: 1, remuneration: remunerations[type],
            })
          })
        }
      })

      const submittedValue = {
        id: workplace.id,
        name: workplace.name,
        contact: workplace.contact,
        phone: workplace.phone,
        address: workplace.address,
        payday: workplace.payday,
        payment_month: workplace.payment_month,
        blocked_at: workplace.blocked_at,
        duties,
      }
      submittedValue.address.complement = submittedValue.address.complement || null
      store.dispatch(`app-workplace/${submittedValue.id ? 'updateWorkplace' : 'addWorkplace'}`, JSON.parse(JSON.stringify(submittedValue)))
        .then(() => {
          router.push('/workplaces')
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Erro ao ${submittedValue.id ? 'editar' : 'adicionar'} unidade`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function getRemunerationType(type) {
      if (type === 'week') return 'dia de semana'
      return 'final de semana'
    }

    function removeDuty(id) {
      const index = workplaceLocal.value.duties.findIndex(d => d.id === id)
      workplaceLocal.value.duties.splice(index, 1)
    }

    return {
      getRemunerationType,
      addDuty,
      workplaceLocal,
      refFormObserver,
      getValidationState,
      stateOptions,
      payDayOptions,
      paymentMonthOptions,
      cityOptions,
      save,
      selectedState,
      selectedCity,
      dutyTypesOptions,
      removeDuty,
      refreshCities,
      searchCep,
      changeDutyType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.card-header {
  padding: 0!important;
}
.duty-header {
  display: block;
  text-align: end;
}

.duty-remove {
  padding: 0.05rem;
}

.duty-label span {
  max-width: 250px;
  display: block;
  font-size: 10px;
}
</style>
