<template>
  <div class="col position-relative -p-0">
    <div class="card shadow-none border-0 mb-0 rounded-0">
      <div class="card-body pb-0 p-0 pt-0">
        <b-row class="match-height">
          <b-col xl="12">
            <div class="calendar-toolbar-chunk d-flex align-items-center">
              <div class="calendar-button-group">
                <button :disabled="loading" class="calendar-prev-button" type="button" aria-label="prev" @click="prevMonth">
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="20"
                  />
                </button>
                <button :disabled="loading" class="calendar-next-button" type="button" aria-label="next" @click="nextMonth">
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="20"
                  />
                </button>
              </div>
              <h2 class="calendar-toolbar-title">
                {{ formatMonth(currentDate) }}
              </h2>
            </div>
          </b-col>
          <b-col xl="12" class="p-0 no-gutters">
            <table class="table-calendar w-100">
              <tbody>
                <template v-for="day in eventsByDate">
                  <tr :key="day.date.toString()">
                    <th colspan="6">
                      <div class="d-flex justify-content-between">
                        <div>{{ formatDay(day.date) }}</div>
                        <div>{{ formatDow(day.date) }}</div>
                      </div>
                    </th>
                  </tr>
                  <tr
                    v-for="(event, i) in day.events"
                    :key="`${day.date.toString()}-${i}`"
                    @click="clickEvent(event)"
                  >
                    <td class="text-nowrap">
                      {{ formatDateIntervalFull(event) }}
                    </td>
                    <td><span class="calendar-dot" :style="{'border-color': event.color}" /></td>
                    <td class="text-nowrap w-100">
                      <div v-html="event.title" />
                    </td>
                    <td class="text-nowrap">
                      <div v-html="event.subtitle" />
                    </td>
                    <td class="text-nowrap">
                      <div v-html="event.subtitle2" />
                    </td>
                    <td class="text-nowrap">
                      <div v-html="event.content" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div v-if="eventsByDate.length === 0" class="list-empty">
              <div>Não há eventos para mostrar</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import {
  addMonths, endOfMonth, format, startOfMonth, subMonths,
} from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { onMounted, ref } from '@vue/composition-api'
import calendarApi from '@/views/physician-duty-services/calendar-api'

export default {
  components: {
    BRow,
    BCol,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDay: date => format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
    formatDow: date => format(date, 'eeee', { locale: ptBR }),
    formatMonth: date => format(date, "MMMM 'de' yyyy", { locale: ptBR }),
    formatDateInterval: duty => `${format(duty.start, 'hh:mm')} - ${format(duty.end, 'hh:mm')}`,
    formatDateIntervalFull: duty => `${format(duty.start, 'dd/MM/yy hh:mm')} - ${format(duty.end, 'dd/MM/yy hh:mm')}`,
  },
  setup(props) {
    const loading = ref(false)
    const currentDate = ref(startOfMonth(new Date()))
    // const currentDate = ref(addMonths(startOfMonth(new Date()), 3))
    const eventsByDate = ref([])

    function clickEvent(event) {
      props.options.eventClick({ event })
    }

    function transform(events) {
      const dates = {}
      events.forEach(item => {
        const dt = format(item.start, 'yyyy-MM-dd')
        if (!dates[dt]) dates[dt] = []
        dates[dt].push(item)
      })
      const groups = []
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const date in dates) {
        groups.push({
          date: new Date(`${date}T00:00:00`),
          events: dates[date],
        })
      }
      return groups
    }

    const fetchEvents = () => {
      loading.value = true
      const info = {
        startStr: format(startOfMonth(currentDate.value), 'yyyy-MM-dd'),
        endStr: format(endOfMonth(currentDate.value), 'yyyy-MM-dd'),
      }
      props.options.events(info, events => {
        loading.value = false
        eventsByDate.value = transform(events)
      })
    }

    calendarApi.onRefetchEvents(() => {
      fetchEvents()
    })

    onMounted(() => {
      fetchEvents()
    })

    function nextMonth() {
      currentDate.value = startOfMonth(addMonths(currentDate.value, 1))
      fetchEvents()
    }

    function prevMonth() {
      currentDate.value = startOfMonth(subMonths(currentDate.value, 1))
      fetchEvents()
    }

    return {
      currentDate,
      clickEvent,
      eventsByDate,
      nextMonth,
      prevMonth,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.calendar-toolbar-chunk {
  display: flex;
  button {
    background-color: transparent;
    border-color: transparent;
  }
  .calendar-icon {
    font-size: 1.5em;
    font-family: 'fcicons';
  }
  .calendar-button-group {
    display: flex;
  }
  .calendar-prev-button {
    color: #6e6b7b;
  }
  .calendar-icon-chevron-left::before {
    content: "\e900";
    font-size: 1.5em;
  }
}

.table-calendar {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  .calendar-dot {
    border: 5px solid #3788d8;
    border-radius: 5px;
    font-size: 1em;
    display: inline-block;
    box-sizing: content-box;
    width: 0;
    height: 0;
  }

  th {
    background: #f8f8f8;
    padding: 8px 14px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 8px 14px;
    border-color: #ebe9f1;
  }
  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  tr:hover {
    td {
      background-color: #f8f8f8;
    }
  }
}

.calendar-button-group {
  margin-bottom: 0.5rem;
}

.list-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(208, 208, 208, 0.3);
  div {
    text-align: center;
  }
}
</style>
