import Vue from 'vue'

const CalendarApi = new Vue()
export default {
  refetchEvents: () => {
    CalendarApi.$emit('refetch')
  },
  onRefetchEvents: callback => {
    CalendarApi.$on('refetch', callback)
  },
}
