<template>
  <div class="app-calendar overflow-hidden border">
    <div class="card shadow-none border-0 mb-0 rounded-0">
      <div class="card-body pb-0 d-flex justify-content-between">
        <h4 class="mb-0">
          {{ workplaceData.name }}
        </h4>
      </div>
    </div>

    <div class="d-flex no-gutters">
      <!-- Sidebar -->
      <div
        class="app-calendar-sidebar flex-grow-0 -overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" />
      </div>

      <!-- Calendar -->
      <div class="flex-grow-1 position-relative overflow-x-scroll">
        <div class="card shadow-none border-0 mb-0 card-h-100 rounded-0">
          <div class="card-body pb-0 p-0 pt-0 ">
            <month-calendar :options="calendarOptions" />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :person-type="personType"
        :clear-event-data="clearEventData"
        @remove-physician-duty="removePhysicianDuty"
        @add-physician-duty="addPhysicianDuty"
        @update-physician-duty="updatePhysicianDuty"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import router from '@/router'
import workplaceStoreModule from '@/views/workplace/workplaceStoreModule'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import MonthCalendar from './MonthCalendar.vue'

export default {
  components: {
    MonthCalendar,
    CalendarSidebar,
    CalendarEventHandler,
  },
  beforeRouteEnter(to, from, next) {
    const CALENDAR_APP_STORE_MODULE_NAME = 'physician-duty'
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    store.dispatch('physician-duty/setWorkplaceId', to.params.id)
    next()
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'physician-duty'
    const WORKPLACE_APP_STORE_MODULE_NAME = 'app-workplace'

    const personType = ref('PF')
    const workplaceData = ref({})

    const updateCurrentWorkplace = id => store.dispatch('physician-duty/setWorkplaceId', id)

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    if (!store.hasModule(WORKPLACE_APP_STORE_MODULE_NAME)) store.registerModule(WORKPLACE_APP_STORE_MODULE_NAME, workplaceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      updateCurrentWorkplace(null)
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    updateCurrentWorkplace(router.currentRoute.params.id)

    function currency(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addPhysicianDuty,
      updatePhysicianDuty,
      removePhysicianDuty,
      refetchEvents,
      fetchPhysicians,
      calendarOptions,
      fetchDutyTypes,
      isEventHandlerSidebarActive,
    } = useCalendar()

    store.dispatch('app-workplace/fetchWorkplace', { id: router.currentRoute.params.id })
      .then(response => {
        const duties = response.data.data.duties.map(duty => {
          const remuneration = duty.remuneration / 100
          return { ...duty, remuneration }
        })
        workplaceData.value = { ...response.data.data, duties }
        personType.value = workplaceData.value.person_type
      })

    fetchPhysicians()

    watch(() => isEventHandlerSidebarActive, () => {
      fetchDutyTypes()
    })

    return {
      currency,
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addPhysicianDuty,
      updatePhysicianDuty,
      removePhysicianDuty,
      refetchEvents,
      calendarOptions,
      workplaceData,
      isEventHandlerSidebarActive,
      personType,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.app-calendar .app-calendar-sidebar, .card-h-100 {
  //min-height: 100vh!important;
}

.fc-event-time {
  color: #3C3C3B;
}

.fc-event-title {
  font-weight: bold;
  color: #575756;
  text-transform: capitalize;
}

.fc-list-event-dot {
}

</style>
