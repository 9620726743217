import { ref, computed, watch } from '@vue/composition-api'
import {
  parse,
} from 'date-fns'
import { porExtenso, estilo } from 'numero-por-extenso'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const amountPaidText = ref('zero reais')
  const resetEventLocal = () => {
    const value = JSON.parse(JSON.stringify(props.event.value))
    value.extendedProps.amountPaid = value.extendedProps.amountPaid || 0
    value.extendedProps.workplaceDutyId = value.extendedProps.workplaceDutyId || (value.extendedProps.workplaceDuty && value.extendedProps.workplaceDuty.id)
    eventLocal.value = value
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  watch(eventLocal, () => {
    const amountPaid = eventLocal.value.extendedProps && eventLocal.value.extendedProps.amountPaid
    amountPaidText.value = amountPaid ? porExtenso(amountPaid, estilo.monetario) : ''
  }, { deep: true })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state['physician-duty'].calendarOptions)

  const getColor = eventData => {
    if (eventData.confirmed) return 'rgba(33,197,98,0.55)'
    if (eventData.released) return 'rgba(171,86,209,0.73)'
    return 'rgba(42,42,42,0.12)'
  }

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))
    eventData.value.extendedProps.personType = props.personType.value

    // if (props.personType.value === 'PJ' && !eventData.value.extendedProps.workplaceDutyId) {
    //   this.$refs.loginValidation.setErrors({
    //     password: 'Dispositivo não passou no teste de segurança',
    //   })
    //   return false
    // }

    const start = parse(eventData.value.start, 'dd/MM/yyyy HH:mm', new Date())
    const end = parse(eventData.value.end, 'dd/MM/yyyy HH:mm', new Date())
    const color = getColor(eventData.value)

    if (props.event.value.id) {
      emit('update-physician-duty', {
        ...eventData.value, start, end, color,
      })
    } else {
      emit('add-physician-duty', {
        ...eventData.value, start, end, color,
      })
    }

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)

    return true
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    amountPaidText,
    eventLocal,
    resetEventLocal,
    calendarOptions,
    // UI
    onSubmit,
  }
}
