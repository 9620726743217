import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        action: 'read',
        resource: 'Home',
      },
    },
    {
      path: '/workplaces',
      name: 'workplaces',
      component: () => import('@/views/workplace/workplace-list/Workplace.vue'),
      meta: {
        action: 'read',
        resource: 'Workplace',
      },
    },
    {
      path: '/workplace/new',
      name: 'workplace-new',
      component: () => import('@/views/workplace/workplace-form/WorkplaceForm.vue'),
      meta: {
        action: 'create',
        resource: 'Workplace',
      },
    },
    {
      path: '/workplace/edit/:id',
      name: 'workplace-edit',
      component: () => import('@/views/workplace/workplace-form/WorkplaceForm.vue'),
      meta: {
        action: 'edit',
        resource: 'Workplace',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      props: true,
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    // *===============================================---*
    // *--------- Relatorio ------------------------------*
    // *===============================================---*
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/report/report/Report.vue'),
      meta: {
        action: 'read',
        resource: 'Report',
      },
    },
    {
      path: '/reports/accountability',
      name: 'reports-accountability',
      component: () => import('@/views/report/report-accountability/ReportAccountability.vue'),
      meta: {
        action: 'read',
        resource: 'ReportAccountability',
      },
    },
    // *===============================================---*
    // *--------- USER -----------------------------------*
    // *===============================================---*
    {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        action: 'list',
        resource: 'User',
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        action: 'view',
        resource: 'User',
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        action: 'edit',
        resource: 'User',
      },
    },
    // *===============================================---*
    // *--------- PHYSICIAN ------------------------------*
    // *===============================================---*
    {
      path: '/physician/list',
      name: 'physician-list',
      component: () => import('@/views/physician/physician-list/PhysicianList.vue'),
      meta: {
        action: 'read',
        resource: 'Physician',
      },
    },
    {
      path: '/physician/new',
      name: 'physician-new',
      component: () => import('@/views/physician/physician-new/PhysicianNew.vue'),
      meta: {
        action: 'create',
        resource: 'Physician',
      },
    },
    {
      path: '/physician/view/:id',
      name: 'physician-view',
      component: () => import('@/views/physician/physician-view/PhysicianView.vue'),
      meta: {
        action: 'view',
        resource: 'Physician',
      },
    },
    {
      path: '/physician/edit/:id',
      name: 'physician-edit',
      component: () => import('@/views/physician/physician-edit/PhysicianEdit.vue'),
      meta: {
        action: 'edit',
        resource: 'Physician',
      },
    },
    {
      path: '/imports/physician',
      name: 'import-physician',
      component: () => import('@/views/imports/import-list/ImportPhysicianList.vue'),
      meta: {
        action: 'read',
        resource: 'ImportPhysician',
      },
    },
    {
      path: '/imports/physician/:id',
      name: 'import-physician-view',
      component: () => import('@/views/imports/import-view/PhysicianImportView.vue'),
      meta: {
        action: 'read',
        resource: 'ImportPhysician',
      },
    },
    // *===============================================---*
    // *--------- physician-duty -------------------------------*
    // *===============================================---*
    {
      path: '/workplace/physician-duty-services/:id',
      name: 'physician-duty-services-edit',
      component: () => import('@/views/physician-duty-services/Calendar.vue'),
      meta: {
        action: 'edit',
        resource: 'PhysicianDuty',
      },
    },
    {
      path: '/workplace/physician-duty/:id',
      name: 'physician-duty-edit',
      component: () => import('@/views/physician-duty/Calendar.vue'),
      meta: {
        action: 'edit',
        resource: 'PhysicianDuty',
      },
    },
    {
      path: '/authorizations',
      name: 'authorizations',
      component: () => import('@/views/authorizations/Authorizations.vue'),
      meta: {
        action: 'edit',
        resource: 'Authorization',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'misc-not-authorized',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
